import PropTypes from "prop-types"
import React from "react"

import styles from "./styles.module.scss"

const Splash = ({ siteTitle }) => (
  <section className={styles.splash}>
    <h1 className="italic">{siteTitle}</h1>
  </section>
)

Splash.propTypes = {
  siteTitle: PropTypes.string,
}

Splash.defaultProps = {
  siteTitle: ``,
}

export default Splash
