import React from "react"

import Layout from "../components/Layout"
import Nav from "../components/Nav"
import SEO from "../components/seo"
import Splash from "../components/Splash"

import styles from "./styles.module.scss"

const IndexPage = () => (
  <Layout classNameLayout={ styles.home } contentWidth="narrow" id="home">
    <SEO title="Home" />
    <div className={ styles.text }>
      <h1 className="h2">Get <em>Lost and Bound</em> with us...</h1>
      <p><em>Lost and Bound</em> is a quarterly digital lifestyle zine for speculative identities, launching in summer 2021.</p>
      <Nav className="home" variant="cta" />
    </div>
  </Layout>
)

export default IndexPage
